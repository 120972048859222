import React, {useEffect, useState} from "react"
import {graphql, Link} from "gatsby"
import Layout from "../components/layout"
import Seo from "../components/seo"
import AlbumSlider from "../components/album-slider";

import * as dayjs from 'dayjs';
import 'dayjs/locale/nl';
import {PhotoAlbum} from "react-photo-album";

import Lightbox from "yet-another-react-lightbox";
import "yet-another-react-lightbox/styles.css";
import Fullscreen from "yet-another-react-lightbox/plugins/fullscreen";
import Slideshow from "yet-another-react-lightbox/plugins/slideshow";
import Thumbnails from "yet-another-react-lightbox/plugins/thumbnails";
import Zoom from "yet-another-react-lightbox/plugins/zoom";
import "yet-another-react-lightbox/plugins/thumbnails.css";
import {useMediaQuery} from "react-responsive";
import PlaySVG from '../assets/svg/play.svg';

const BlogIndex = ({
  data : {
      homeCover: {
          cover
      },
      events,
      albums,
      photos
  }
}) => {

    dayjs.locale('nl');
    const isMD = useMediaQuery({
        query: '(min-width: 768px)'
    })

    const [index, setIndex] = useState(-1);
    const formattedPhotos = photos.albumPhotos.map(({external_link, width, height}) => {
       return {
           src: external_link,
           width,
           height
       }
    });

  return (
    <Layout isDark isHomePage>
      <Seo />

      <section className={"w-full min-h-screen bg-dark-gray pt-10 flex flex-col items-center space-y-6 bg-fixed bg-cover bg-jan-2 bg-no-repeat"}>
          <div className={"jdb--menu-spacer"} />
          <div className={"mt-0.5"}><h1 className={"text-white text-6xl font-black md:text-9xl"}>{cover.naam}</h1></div>
          <div className={"text-red-600 text-4xl md:text-5xl font-primary"}>{cover.subtitel}</div>
          <div className={"text-white w-2/3 md:w-1/3 text-center"}>{cover.omschrijving}</div>
          <div className={"wflex items-center justify-center max-w-[200px] sm:max-w-[400px]"}>
              <a href={"https://distrokid.com/hyperfollow/jandebruijn/the-river"} rel={"nofollow"} target={"_blank"}
                 className="flex items-center rounded-lg bg-green-700 px-4 py-2 text-white">
                  <PlaySVG className={"max-h-fit w-8 fill-white pr-2"} /> <span className="font-medium">Beluister op Spotify, Apple Music of Deezer</span>
              </a>
          </div>
          <div className={"w-2/3 md:w-1/3 pb-20"}>
              <img src={cover.afbeelding.sourceUrl} alt={cover.naam} />
          </div>
      </section>

      <section className={"w-full bg-white pt-10 flex flex-col items-center pb-10 font-primary text-black"}>
          <div className={"pb-2"}><h1 className={"h1"}>Agenda</h1></div>
          <div className={"font-secondary text-red-600 text-2xl uppercase"}>Catch me if you can</div>
          <div className={"flex flex-col w-5/6 lg:w-2/3 mt-10 divide-y divide-dashed divide-gray-700 space-y-3"}>
              {events.edges.map((event) => {
                  const momentStartDate = dayjs(event.node.startDate);
                 return (
                     <div className={"flex flex-row w-full pt-3"} key={event.node.id}>
                         <div className={"flex flex-row w-24"}>
                             <div className={"font-black text-6xl"}>{momentStartDate.format('DD')}</div>
                         </div>
                         <div className={"flex flex-row w-28"}>
                             <div className={"flex flex-col justify-center font-secondary mt-4"}>
                                 <div>{momentStartDate.format('MMMM')}</div>
                                 <div>{momentStartDate.format('dddd')}</div>
                             </div>
                         </div>
                         <div className={"font-semibold font-secondary text-xl flex items-center mt-4"}>
                             {event.node.title}
                         </div>
                     </div>
                 )
              })}
          </div>

          <div className={"w-2/3 flex justify-center pt-10"}>
              <a href={"https://www.facebook.com/jandebruijnmusic"} rel={"nofollow"} target={"_blank"} className={"jdb--button-blue"}>Bekijk alle events</a>
          </div>
      </section>
        <section className={"w-full bg-black flex flex-row bg-no-repeat"}>
            <div className={"w-full flex flex-col justify-center items-center pb-10 pt-10 bg-jan-3 bg-cover bg-center bg-no-repeat bg-fixed min-h-[700px]"}>
              <div className={""}><h1 className={"text-white h1 mb-2"}>Releases</h1></div>
              <div className={"text-secondary font-secondary text-4xl uppercase"}>Damn I'm good</div>
            </div>
        </section>
        <section className={"jdb--albums bg-white"}>
            <div className={"w-full flex justify-center"}>
                <div className={"w-2/3"}>
                    <AlbumSlider albums={albums.nodes ? albums.nodes.slice(0, 4) : null}  />
                </div>
            </div>
            <div className={"w-full flex justify-center pt-5 pb-10"}>
                <Link to={"/releases"} className={"jdb--button-blue"}>Bekijk alle releases</Link>
            </div>
        </section>

        <section className={"w-full bg-black"}>
            <PhotoAlbum layout="columns"
                        columns={isMD ? 3 : 2}
                        padding={0}
                        spacing={0}
                        photos={formattedPhotos}
                        onClick={(event, photo, index) => setIndex(index)}/>
            <Lightbox
                slides={formattedPhotos}
                open={index >= 0}
                index={index}
                close={() => setIndex(-1)}
                // enable optional lightbox plugins
                plugins={[Fullscreen, Slideshow, Thumbnails, Zoom]}
            />
        </section>

        <section className={"w-full min-h-screen bg-dark-gray flex flex-row bg-jan bg-no-repeat bg-cover bg-fixed"}>
            <div className={"w-full md:w-1/2 min-h-screen text-white flex justify-center bg-black/70 p-10 md:p-0"}>
                <div className={"w-full md:w-2/3 text-lg flex flex-col space-y-5 justify-center font-secondary"}>
                    <h2 className={"text-4xl md:text-6xl font-sans font-black"}>Jan de Bruijn</h2>
                    <h3 className={"font-primary font-normal text-2xl mt-0"}>Zanger-gitarist Jan de Bruijn (Rijsbergen, 1958) speelt al meer dan vijfendertig jaar uit het hoofd en uit het hart. Recht uit het hart.</h3>
                    <Link to={"/biografie"} className={"jdb--button-blue font-primary text-center"}>Lees mijn verhaal</Link>
                </div>
            </div>
            <div className={"hidden md:visible w-1/2 min-h-screen"} />
        </section>
    </Layout>
  )
}

export default BlogIndex

export const pageQuery = graphql`
  query WordPressPostArchive($currentDate: Date!) {
    homeCover: wpPage(slug: {eq: "home-ad"}) {
        cover {
          afbeelding {
            sourceUrl
          }
          naam
          omschrijving
          subtitel
        }
    }
    
    events:   allWpEvent(sort: {order: ASC, fields: startDate}, filter: {date: {gte: $currentDate}}, limit: 5) {
        edges {
          node {
            title
            startDate
            endDate
            id
          }
        }
      }
      
      
    
    about: wpPage(slug: {eq: "over-jan"}) {
        id
        title
        content
    }
    
    albums: allSpotifyArtistAlbum(sort: {fields: release_date, order: DESC}) {
        nodes {
          name
          images {
            width
            height
            url
          }
          release_date
          uri
          external_urls {
            spotify
          }
          album_type
          album_group
          total_tracks
        }
      }
      
    photos: jdb {
        albumPhotos {
            external_link
            image_url
            width
            height
        }
      }
  }
`
